var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [_vm._v(" " + _vm._s(_vm.$t("Waste.waste_form_edit_title")))]
            : [_vm._v(_vm._s(_vm.$t("Waste.waste_form_title")))],
        ],
        2
      ),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.isLoadingForm ? _c("Loading") : _vm._e(),
          !_vm.isLoadingForm
            ? _c(
                "form",
                {
                  staticClass: "Form",
                  attrs: { id: "form-waste" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmitForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Waste.form_store"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("custom-search", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|is_not:-1|verify_inventory",
                              expression:
                                "'required|is_not:-1|verify_inventory'",
                            },
                          ],
                          ref: "firstInput",
                          attrs: {
                            "component-name": "waste-from-store-name",
                            name: "storeName",
                            "data-vv-as": _vm.$t("Waste.form_store"),
                            inputClass: {
                              "is-danger":
                                !_vm.inventoryValidation &&
                                _vm.veeErrors.has("storeName"),
                            },
                            "on-search": _vm.getOptionsStore,
                            options: _vm.selectMiniStoreList,
                            label: "name",
                            searchAll: true,
                            withDebounce: false,
                            loading: _vm.isSearchStoreLists,
                          },
                          on: {
                            input: _vm.inputSelection,
                            resetOptions: _vm.resetOptions,
                          },
                          model: {
                            value: _vm.form.storeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "storeId", $$v)
                            },
                            expression: "form.storeId",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.inventoryValidation,
                              expression: "inventoryValidation",
                            },
                          ],
                          attrs: {
                            errorName: _vm.$t("Inventory.closedInventoryError"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "Form-item required" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("Waste.waste_group"))),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control form-item-select" },
                        [
                          _c("Icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isLoadingWasteGroup,
                                expression: "!isLoadingWasteGroup",
                              },
                            ],
                            attrs: { name: "icon-down-arrow" },
                          }),
                          _c("customSelectInput", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|is_not:-1",
                                expression: "'required|is_not:-1'",
                              },
                            ],
                            attrs: {
                              name: "wasteGroup",
                              optionData: _vm.WasteGroupList,
                              "data-vv-as": _vm.$t("Waste.waste_group"),
                              error: _vm.veeErrors.has("wasteGroup"),
                              id: "select-waste-from-waste-group",
                              optionIdName: "option-waste-popup-group-name",
                              disabled:
                                _vm.isLoadingWasteGroup || !_vm.form.storeId,
                            },
                            model: {
                              value: _vm.form.wasteGroupId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "wasteGroupId", $$v)
                              },
                              expression: "form.wasteGroupId",
                            },
                          }),
                          _c(
                            "Button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isLoadingWasteGroup,
                                  expression: "isLoadingWasteGroup",
                                },
                              ],
                              attrs: {
                                variant: "icon loading",
                                id: "select-waste-from-waste-group-loading",
                                type: "button",
                                tabindex: "-1",
                              },
                            },
                            [_c("Loading", { attrs: { theme: "disable" } })],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("wasteGroup"),
                            expression: "veeErrors.has('wasteGroup')",
                          },
                        ],
                        attrs: { errorName: _vm.veeErrors.first("wasteGroup") },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Waste.wasteDate"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("CustomDatepicker", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|verify_inventory",
                              expression: "'required|verify_inventory'",
                            },
                          ],
                          class: {
                            "is-danger": _vm.veeErrors.has("wasteDate"),
                          },
                          attrs: {
                            id: "waste-header-datepicker",
                            name: "wasteDate",
                            "data-vv-as": _vm.$t("Waste.wasteDate"),
                            pickerType: "manuel",
                            inputIconRight: "icon-datepicker-big",
                            inputIcon: "",
                            disabledStartDate: _vm.disableDate,
                          },
                          model: {
                            value: _vm.form.wasteDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "wasteDate", $$v)
                            },
                            expression: "form.wasteDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.inventoryValidation,
                            expression: "inventoryValidation",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.$t("Inventory.closedInventoryError")))]
                    ),
                  ]),
                  !_vm.isEdit
                    ? _c(
                        "div",
                        {
                          staticClass: "Form-item",
                          class: _vm.isOnlyListUsage ? "required" : "",
                        },
                        [
                          _c("label", { staticClass: "Form-item-label" }, [
                            _vm._v(_vm._s(_vm.$t("Waste.Form_List"))),
                          ]),
                          _c(
                            "div",
                            { staticClass: "control" },
                            [
                              _c("custom-search", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      required:
                                        _vm.isOnlyListUsage &&
                                        _vm.selectOptions.length < 1,
                                    },
                                    expression:
                                      "{required: isOnlyListUsage && selectOptions.length < 1}",
                                  },
                                ],
                                attrs: {
                                  "component-name": "select-waste-from-list",
                                  "data-vv-as": _vm.$t("Waste.Form_List"),
                                  "on-search": _vm.getOptions,
                                  options: _vm.selectOptions,
                                  name: "custom-list-search",
                                  label: "name",
                                  searchAll: true,
                                  placeholder: _vm.$t(
                                    "Waste.Form_List_Placeholder"
                                  ),
                                },
                                model: {
                                  value: _vm.form.list,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "list", $$v)
                                  },
                                  expression: "form.list",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.veeErrors.has("custom-list-search") &&
                                  _vm.isOnlyListUsage,
                                expression:
                                  "veeErrors.has('custom-list-search') && isOnlyListUsage",
                              },
                            ],
                            attrs: {
                              errorName:
                                _vm.veeErrors.first("custom-list-search"),
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "selected-items" },
                            _vm._l(_vm.wasteList, function (item, index) {
                              return _c(
                                "Button",
                                {
                                  key: index,
                                  attrs: {
                                    size: "small",
                                    seashell: "",
                                    id: `selected-stock-item-${index}`,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeListItem(item)
                                    },
                                  },
                                },
                                [
                                  _c("span", [_vm._v(_vm._s(item.name))]),
                                  _c("Icon", {
                                    staticClass: "m-left-5",
                                    attrs: {
                                      name: "icon-popup-close",
                                      id: `selected-stock-item-delete-${index}`,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "Form-item" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("Waste.form_description"))),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("customTextareaInput", {
                            staticClass: "txt",
                            attrs: {
                              id: "waste-header-input",
                              name: "description",
                              "data-vv-as": _vm.$t("Waste.form_description"),
                              error: _vm.veeErrors.has("description"),
                            },
                            model: {
                              value: _vm.form.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "description", $$v)
                              },
                              expression: "form.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("description"),
                            expression: "veeErrors.has('description')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first("description"),
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              staticClass: "item",
              attrs: {
                size: "medium",
                primary: "",
                variant: "full",
                id: "btn-transfer-popup-submit",
                type: "submit",
                form: "form-waste",
                disabled:
                  _vm.isLoading ||
                  _vm.inventoryValidation ||
                  _vm.isLoadingWasteGroup,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [_vm._v(_vm._s(_vm.$t("Waste.waste_form_edit_title")))]
                    : [_vm._v(_vm._s(_vm.$t("Waste.waste_form_title")))],
                ],
                2
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }