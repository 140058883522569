<template lang="pug">
Popup

    template(slot="title") {{ $t('Waste.waste_detail') }}

    template(slot="detail")
      div.Popup-waste-info.row
        div.ff-mono {{ wasteHeaderInfo.code }}

        .PopupHeader(v-if="!isEdit")
          .tab
            Button(
            size="small",
            id="btn-waste-popup-tab-header-1"
            :class="{ 'isSelected' : selectedDetailType === 0 }",
            @click="tabChange(0)",
            type="button"
            ) {{ $t('Waste.popupAdd_Header_StockItem') }}
            Button(
            size="small",
            id="btn-waste-popup-tab-header-2"
            :class="{ 'isSelected' : selectedDetailType === 1 }",
            @click="tabChange(1)",
            type="button"
            ) {{ $t('Waste.popupAdd_Header_Recipe') }}

    template(slot="content")
      Loading(v-if="isLoadingForm")
      form.Form(
      v-if="!isLoadingForm"
      id="form-waste-detail"
      @submit.prevent="onSubmitFormWaste")

        .Form-item.required(v-show="selectedDetailType === 0")
          label.Form-item-label {{ $t('Waste.popupAdd_Header_StockItem') }}
          .control
            custom-search(
              ref="firstInput"
              name="stockItem"
              component-name="waste-detail-stock-item"
              v-model="form.stockItem"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_StockItemName')"
              v-validate="{'required' : selectedDetailType === 0}"
              :inputClass="{ 'is-danger': veeErrors.has('stockItem'), 'verify_inventory': true }"
              :on-search="getOptions"
              :options="selectOptions"
              :disabled="isEdit"
              label="name"
              :placeholder="$t('Despatches.detail_Popup_FormField_StockItemName_Placeholder')"
              :directSelectOption="directSelectOption"
              @input="fetchStockItems"
            )
          showValidateError(
            v-show="veeErrors.has('stockItem')"
            :errorName="veeErrors.first('stockItem')"
          )

          .Form-item-info(v-if="form.stockItem")
            div
              .text {{ $t('Waste.detail_form_tb') }}
              .value(
                v-if="selectedStock.unitCode") {{ selectedStock.baseUnitName }}
            div
              .text {{ $t('Waste.averagePrice') }}
              .value {{ selectedStock.baseUnitPrice | formatCurrency }}

        .Form-item.required.recipeField(v-if="selectedDetailType === 1")
          label.Form-item-label {{ $t('Waste.popupAdd_Header_Recipe') }}
          .control
            custom-search(
              component-name="waste-detail-recipe"
              ref="firstInputRecipe"
              name="recipe"
              v-model="form.recipe"
              :data-vv-as="$t('Waste.popupAdd_Header_Recipe')"
              v-validate="{'required' : selectedDetailType === 1}"
              :inputClass="{ 'is-danger': veeErrors.has('recipe'), 'verify_inventory': true }"
              :on-search="getRecipeOptions"
              :options="formNewRecipeSelectOptions"
              :disabled="isEdit"
              label="name"
              :placeholder="$t('Despatches.detail_Popup_FormField_StockItemName_Placeholder')"
              :directSelectOption="recipeDirectSelectOption"
              @afterSelected="setRecipe"
            )
          showValidateError(
            v-show="veeErrors.has('recipe')"
            :errorName="veeErrors.first('recipe')"
          )

          .Form-item-info(v-if="form.recipe")
            div
              .text {{ $t('Waste.detail_form_cost') }}
              .value {{ selectedStock.unitPrice | formatCurrency }}

        .Form-item.required
          label.Form-item-label {{ $t('Despatches.detail_Popup_FormField_Quantity') }}
          .control
            customNumberInput(
              id="waste-detail-number-input"
              name="quantity"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_Quantity')"
              v-model="form.quantity"
              v-validate="{required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), is_not:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
              :error="veeErrors.has('quantity')"
            )

          showValidateError(
            v-show="veeErrors.has('quantity')"
            :errorName="veeErrors.first('quantity')"
          )

          .Form-item-info(v-if="form.stockItem || form.recipe")
            div
              .text {{ $t('Waste.detail_TableColumn_StockOnHandQuantity') }}
              .value {{ stockItemInfo.baseUnitQuantity !== null ? stockItemInfo.baseUnitQuantity : '-' }}

        .Form-item.required
          label.Form-item-label
            | {{ $t('Despatches.detail_Popup_FormField_BaseUnit') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")

            customSelectInput(
              name="unitId"
              :optionData="filteredUnitList"
              v-model="form.unitId"
              v-validate="'required|is_not:-1'"
              :data-vv-as="$t('Despatches.detail_Popup_FormField_BaseUnit')"
              :error="veeErrors.has('unitId')"
              id="select-Waste-popup-baseunit"
              optionIdName="option-Waste-popup-baseunit"
              :disabled="isEdit"
              @change="onStockChange"
            )

            showValidateError(
              v-show="veeErrors.has('unitId')"
              :errorName="veeErrors.first('unitId')"
            )
        .Form-item
          label.Form-item-label {{ $t('Waste.form_description') }}
          .control
            customTextareaInput.txt(
              id="waste-header-input"
              name="description"
              :data-vv-as="$t('Waste.form_description')"
              v-model="form.description"
              :error="veeErrors.has('description')"
            )

          showValidateError(
              v-show="veeErrors.has('description')"
              :errorName="veeErrors.first('description')"
            )

    template(slot="footer")

      div.add-other-items-checkbox-container(v-if="!isEdit")
        CustomCheckbox(
        id="checkbox-despatch-detail-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('Waste.saveAndNewKey') })"
        v-model="saveAndNew")

      Button.item(
      size="medium"
      primary
      variant="full"
      id="btn-Waste-popup-submit"
      type="submit",
      form="form-waste-detail",
      :disabled="isLoading || inventoryValidation")
        span(v-show="!isLoading")
          template(v-if="isEdit") {{ $t('Waste.updateWasteDetail') }}
          template(v-else) {{ $t('Waste.addWasteDetail') }}
        Loading(theme="disable", v-show="isLoading")
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { Validator } from 'vee-validate'
import moment from 'moment'
import CheckInventoryMixin from '@/mixins/validateInventory'
import getQuantityFromBarcode from '@/mixins/getQuantityFromBarcode'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'WasteDetailForm',

  mixins: [CheckInventoryMixin, getQuantityFromBarcode],

  data () {
    return {
      selectedDetailType: 0,
      formNewRecipeSelectOptions: [],
      form: {
        stockItem: null,
        quantity: null,
        unitId: '-1',
        type: 1,
        recipe: null,
        description: null
      },
      wasteHeaderInfo: {},
      stockItem: {},
      selectedStock: {},
      selectOptions: [],
      saveAndNew: true,
      stockItemInfo: {},
      recipeSearch: null,
      dates: [ moment(1, 'DD').hour(0).minute(0)._d, moment().hour(0).minute(0)._d ],
      wasteGroupFilterList: [],
      storeList: [],
      wasteDetailList: [],
    }
  },

  watch: {
    async 'form.stockItem' (val) {
      if (this.isEdit || !val) return
      await this.getStockData(val.id)
      await this.setUnit(val.unitId)
      this.onStockChange()
    },
  },

  computed: {
    ...mapGetters('Units', ['UnitList', 'findUnit', 'getRelatedUnitList']),
    ...mapGetters('Waste', ['Page']),
    isEdit () {
      return this.$route.meta.status === 'edit'
    },
    isFinalized () {
      return this.wasteHeaderInfo.status === '2'
    },
    checkInventoryParams () {
      /*
      *  CheckInventoryMixin için Inventory Validator Parametreleri tanımlandı.
      */
      return {
        storeId: this.wasteHeaderInfo.storeId,
        inventoryDate: this.wasteHeaderInfo.wasteDate,
        stockItemIdList: this.form.type === 1 ? [this.form.stockItem.id] : null,
        recipeHeaderList: this.form.type === 2 ? [this.form.recipe.id] : null
      }
    },
    filteredUnitList () {
      if (this.selectedDetailType === 0) {
        return this.form.stockItem ? this.UnitList.filter(item => (item.baseUnitId === this.form.stockItem.baseUnitId) && (item.isRecipeUnit === false)) : []
      } else {
        return this.form.recipe ? this.UnitList.filter(item => (item.baseUnitId === this.form.recipe.totalUnitId) && (item.isRecipeUnit === false)) : []
      }
    },

    isLoading () {
      return this.$wait.is(['Create_Waste_Header_detail', 'Update_Waste_Header_detail', 'onSubmitFormWasteDetail'])
    },
    isLoadingForm () {
      return this.$wait.is(['Get_Waste_Detail'])
    },
    directSelectOption () {
      return this.selectOptions.length === 1 &&
      this.selectOptions[0].matchType === 2
        ? this.selectOptions[0]
        : {}
    },
    isRecipeBarcodeSearch () {
      return this.formNewRecipeSelectOptions.length === 1 &&
        this.formNewRecipeSelectOptions[0].matchType === 2
    },
    recipeDirectSelectOption () {
      return this.isRecipeBarcodeSearch
        ? this.formNewRecipeSelectOptions[0]
        : {}
    }
  },

  created () {
    Validator.extend('type_required', {
      getMessage: () => {
        const field = this.selectedDetailType === 0 ? this.$t('Waste.popupAdd_Header_StockItem') : this.$t('Waste.popupAdd_Header_Recipe')
        this.$t('Waste.valide_msg', { field })
      },
      // Custom validation rule
      validate: (value) => new Promise(resolve => {
        resolve({
          valid: this.form.type === 1 ? this.form.stockItem !== null : this.form.recipe !== null
        })
      })
    })
  },
  methods: {
    ...mapActions('Stock', ['searchStockItem', 'getStockItem']),

    ...mapActions('Waste', [
      'getWasteHeader',
      'getWasteDetail',
      'createWasteHeaderDetail',
      'updateWasteHeaderDetail',
      'getWasteHeaderAll'
    ]),

    ...mapActions('Recipes', ['searchRecipeItemByName', 'get_RECIPE_HEADER']),

    ...mapActions('Notify', ['notifyShow']),

    ...mapActions('Transfer', ['getStockInfo']),

    ...mapMutations('Waste', ['SHOW_DETAIL', 'HIDE_OTHERS_DETAILS', 'UPDATE_WASTE_HEADER', 'Get_Waste_Detail']),

    setRecipe (option) {
      this.get_RECIPE_HEADER(option.id)
        .then(res => {
          this.form.recipe = res.data.recipeHeader
          if (this.isRecipeBarcodeSearch) {
            const unitId = res.data.recipeHeader.totalUnitId
            const search = this.recipeSearch
            const parsedBarcode = this.getQuantityFromBarcode(search, unitId)
            if (parsedBarcode.isWeighted) {
              this.form.quantity = parsedBarcode.quantity
            }
          }
          this.setUnit(this.form.recipe.totalUnitId)
          this.fetchStockItems()
        })
    },

    focusFirstInput () {
      if (this.selectedDetailType === 0) {
        this.$refs.firstInput.$refs.search.focus()
      } else {
        this.$refs.firstInputRecipe.$refs.search.focus()
      }
    },

    async fetchStockItems () {
      const payload = {
        storeId: this.wasteHeaderInfo.storeId,
        stockItemId: this.selectedDetailType === 0 ? this.form.stockItem.id : null,
        unitId: this.selectedDetailType === 0 ? this.form.stockItem.baseUnitId : this.form.recipe.unitId,
        forDate: moment(new Date()).format('YYYY-MM-DD'),
        recipeHeaderId: this.selectedDetailType === 1 ? this.form.recipe.id : null
      }
      await this.getStockInfo(payload).then(res => {
        this.stockItemInfo = res.data
      })
    },

    tabChange (index) {
      this.formReset()
      this.$validator.reset()
      this.selectedDetailType = index
      this.form.type = index + 1
      this.$nextTick(() => {
        this.focusFirstInput()
      })
    },

    getOptions (search) {
      this.searchStockItem({Text: search, types: [1, 2, 3]})
        .then(res => {
          this.selectOptions = res.data.items
          this.stockItem = res.data.items
        })
    },

    getRecipeOptions (search) {
      const decoratedValue = this.getWeightedBarcode(search)
      this.recipeSearch = search
      this.searchRecipeItemByName({name: decoratedValue, types: [1, 2, 3]})
        .then(res => {
          this.formNewRecipeSelectOptions = res.data.recipeHeaderList
          if (this.isRecipeBarcodeSearch) {
            this.setRecipe(this.selectedOptionsRecipe[0])
          }
        })
    },

    async getStockData (id) {
      await this.getStockItem(id).then(res => {
        if (res.status === 200) {
          this.stockItem = res.data.stockItem
        }
      })
    },
    setUnit (unitId) {
      this.form.unitId = unitId
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    formReset () {
      this.form = {
        stockItem: null,
        quantity: null,
        unitId: '-1',
        type: 1,
        recipe: null,
        description: null
      }
    },
    async newWasteDetail () {
      const payload = {
        stockItemId: this.selectedDetailType === 0 ? this.form.stockItem.id : null,
        recipeHeaderId: this.selectedDetailType === 1 ? this.form.recipe.id : null,
        unitId: this.form.unitId,
        quantity: this.form.quantity,
        type: this.form.type,
        wasteHeaderId: this.wasteHeaderInfo.id,
        description: this.form.description
      }

      const res = await this.createWasteHeaderDetail(payload)

      if (res) {
        const message = this.$t('Waste.detail_create_Notification_message')
        this.notifyShow({ message })
        const list = await this.getWasteHeader(this.$route.params.id)
        this.SHOW_DETAIL(list.data)
        this.UPDATE_WASTE_HEADER(list.data)
        if (this.saveAndNew) {
          this.form.stockItem = null
          this.form.quantity = null
          this.form.unitId = -1
          this.form.recipe = null
          this.$nextTick(() => {
            this.$validator.reset()
            this.focusFirstInput()
          })
        } else {
          this.close()
        }
      }
    },

    async editWasteDetail () {
      const payload = {
        WasteDetailId: this.$route.params.detailId,
        quantity: this.form.quantity,
        description: this.form.description,
      }

      const res = await this.updateWasteHeaderDetail(payload)

      if (res) {
        const message = this.$t('Waste.detail_update_Notification_message')
        this.notifyShow({ message })
        const list = await this.getWasteHeader(this.$route.params.id)
        this.SHOW_DETAIL(list.data)
        this.close()
      }
    },

    onSubmitFormWaste: vueWaitLoader(async function () {
      const result = await this.$validator.validateAll()
      if (!result) return

      if (this.isEdit) await this.editWasteDetail()
      else await this.newWasteDetail()
    }, 'onSubmitFormWasteDetail'),

    async onStockChange () {
      const requestData = {
        StoreId: this.wasteHeaderInfo.storeId,
        StockItemId: this.selectedDetailType === 0 ? this.stockItem.id : null,
        recipeHeaderId: this.selectedDetailType === 1 ? this.form.recipe.id : null,
        UnitId: this.form.unitId,
        ForDate: moment(this.wasteHeaderInfo.wasteDate).format('YYYY-MM-DD')
      }

      const res = await this.getStockInfo(requestData)
      // const unit = await this.filteredUnitList.find(item => item.code === res.data.unitCode)
      // const unitName = unit ? unit.name : ''
      this.selectedStock = ({
        ...res.data
      })
    }
  },
  async mounted () {
    this.$nextTick(() => {
      this.focusFirstInput()
    })
    const headerInfo = await this.getWasteHeader(this.$route.params.id)
    this.wasteHeaderInfo = ({
      ...headerInfo.data.wasteHeader
    })
    if (this.isEdit) {
      const wasteDetail = await this.getWasteDetail(this.$route.params.detailId)
      const type = wasteDetail.data.wasteDetail.type
      this.selectedDetailType = type - 1
      if (type === 1) {
        this.form = {
          ...wasteDetail.data.wasteDetail,
          stockItem: {
            id: wasteDetail.data.wasteDetail.stockItemId,
            name: wasteDetail.data.wasteDetail.name,
            unitId: wasteDetail.data.wasteDetail.unitId,
            baseUnitId: wasteDetail.data.wasteDetail.baseUnitId
          }
        }
        this.stockItem = {
          id: wasteDetail.data.wasteDetail.stockItemId,
          name: wasteDetail.data.wasteDetail.name,
          unitId: wasteDetail.data.wasteDetail.unitId,
          baseUnitId: wasteDetail.data.wasteDetail.baseUnitId
        }
        this.onStockChange()
      } else {
        this.form = {
          ...wasteDetail.data.wasteDetail,
          recipe: {
            id: wasteDetail.data.wasteDetail.recipeHeaderId,
            name: wasteDetail.data.wasteDetail.name,
            unitId: wasteDetail.data.wasteDetail.unitId,
            baseUnitId: wasteDetail.data.wasteDetail.baseUnitId
          }
        }
        this.setRecipe(this.form.recipe)
        this.onStockChange()
      }
      await this.fetchStockItems()
    }
  }
}
</script>

<style lang="scss">

.PopupHeader {
  padding-bottom: 0;
  margin-top: 27px;

  .tab {
    display: flex;
    margin-top: 27px;
    margin-bottom: -28px;
    button {
      margin-right: 20px;
      font-size: 1.6rem;
      padding-bottom: 6px;
      border-bottom: 4px solid transparent;
      font-weight: $font-weight-normal;

      &.isSelected {
        pointer-events: none;
        color: $color-primary;
        border-bottom-color: $color-primary;
      }
    }
  }
}

.Popup-waste-info {
  margin-top: 13px;
  font-weight: $font-weight-bold;
  display: flex;
  &.row {
    flex-direction: column;
    justify-content: center;
  }
  div {
    margin-right: 30px;
    margin-top: 9px;
    svg {
      color: $color-success;
      margin-right: 5px;
    }
  }
}
.Form-item {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.recipeField {
  margin-top: 0 !important;
}
.Form-item-info {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
  font-size: $font-size-small;

  > div {
    display: flex;
    margin-right: 20px;
    align-items: center;

    select {
      margin-bottom: -2px;
      border: 0;
      background-color: $color-gray;
    }
  }

  .text {
    color: $color-success;
    font-weight: bold;
    margin-right: 6px;
  }

  .value {
    font-weight: $font-weight-bold;
    &:not(:last-child) {
      margin-right: 1.6%
    }
  }
}

.Form-item-info-multiple {
  display: flex;
  align-items: center;
  font-size: $font-size-small;
  flex-wrap: wrap;
  margin-top: 17px;

  &-row {
    display: flex;
    justify-content: space-between;
    border: solid $color-gray 1px;
    width: 100%;
    padding: 17px 10px;
  }

  &-row:first-child {
    border-bottom: none;
  }

  .text {
    color: $color-success;
    font-weight: bold;
    margin-right: 6px;
  }

  .value {
    font-weight: $font-weight-bold;
  }
}

.margin-discount {
  margin-top: 17px;
}
</style>
