export default function getFields($t, columns) {
  const wasteGroupColumn = {
    name: 'wasteGroupName',
    is_visible: true,
    style: 'width: 20%',
    get short_title() {
      return $t('WasteReports.tableColumn_WasteName_Short')
    },
    get title() {
      return $t('WasteReports.tableColumn_WasteName')
    }
  }

  const groupColumn = {
    name: 'groupName',
    is_visible: true,
    style: 'width: 20%',
    get short_title() {
      return columns.groupType.name
    },
    get title() {
      return columns.groupType.name
    }
  }

  const staticColumns = [{
    name: 'totalAmount',
    is_visible: true,
    type: 'currency',
    style: 'width: 20%',
    get short_title() {
      return $t('WasteReports.tableColumn_TotalAmount_Short')
    },
    get title() {
      return $t('WasteReports.tableColumn_TotalAmount_Short')
    }
  }, {
    name: 'spacer',
    is_visible: true,
    type: 'spacer'
  }]

  let fields = []

  if (columns.groupType) {
    fields.push(groupColumn)
  }

  if (columns.includeWasteGroup) {
    if (columns.isWasteGroupPrimary) {
      fields = [wasteGroupColumn].concat(fields)
    } else {
      fields.push(wasteGroupColumn)
    }
  }

  return fields.concat(staticColumns)
}
