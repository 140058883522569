
export function getWasteBatchHeaderFields($t) {
  return [
    {
      name: 'stockItemName',
      is_visible: true,
      get short_title() {
        return $t('Transfer.detail_field_name')
      },
      get title() {
        return $t('Transfer.detail_field_name')
      }
    },
    {
      name: 'unitCode',
      is_visible: true,
      get short_title() {
        return $t('Transfer.unitCode')
      },
      get title() {
        return $t('Transfer.unitCode')
      }
    },
    {
      name: 'fromStoreName',
      is_visible: true,
      get short_title() {
        return $t('Transfer.fromStore')
      },
      get title() {
        return $t('Transfer.fromStore')
      }
    },
    {
      name: 'toStoreName',
      is_visible: true,
      get short_title() {
        return $t('Transfer.buyerStore')
      },
      get title() {
        return $t('Transfer.buyerStore')
      }
    },
    {
      name: 'quantity',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Transfer.quantity')
      },
      get title() {
        return $t('Transfer.quantity')
      }
    },
    {
      name: 'remainingQuantity',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Transfer.remainingQuantity')
      },
      get title() {
        return $t('Transfer.remainingQuantity')
      }
    }
  ]
}

export function getWasteBatchDetailFields($t) {
  return [
    {
      name: 'quantity',
      is_visible: true,
      type: 'mono',
      get short_title() {
        return $t('Transfer.quantity')
      },
      get title() {
        return $t('Transfer.quantity')
      }
    },
    {
      name: 'givenQuantity',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('Transfer.givenQuantity')
      },
      get title() {
        return $t('Transfer.givenQuantity')
      }
    },
    {
      name: 'unitCode',
      is_visible: true,
      get short_title() {
        return $t('Transfer.unitCode')
      },
      get title() {
        return $t('Transfer.unitCode')
      }
    },
    {
      name: 'expirationDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('Transfer.expirationDate')
      },
      get title() {
        return $t('Transfer.expirationDate')
      }
    },
    {
      name: 'degree',
      is_visible: true,
      get short_title() {
        return $t('Transfer.degree')
      },
      get title() {
        return $t('Transfer.degree')
      }
    },

    {
      name: 'warehouseTime',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Transfer.warehouseTime')
      },
      get title() {
        return $t('Transfer.warehouseTime')
      }
    },
    {
      name: 'shipmentTime',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Transfer.shipmentTime')
      },
      get title() {
        return $t('Transfer.shipmentTime')
      }
    },
    {
      name: 'lotNumber',
      is_visible: true,
      get short_title() {
        return $t('Transfer.lotNumber')
      },
      get title() {
        return $t('Transfer.lotNumber')
      }
    },
    {
      name: 'shelfStorageLocationListHeaderName',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Transfer.shelfStorageLocationListHeaderName')
      },
      get title() {
        return $t('Transfer.shelfStorageLocationListHeaderName')
      }
    }
  ]
}
