var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [_vm._v(" " + _vm._s(_vm.$t("WasteGroup.editWasteGroup")))]
            : [_vm._v(_vm._s(_vm.$t("WasteGroup.addWasteGroup")))],
        ],
        2
      ),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.isLoadingForm ? _c("Loading") : _vm._e(),
          !_vm.isLoadingForm
            ? _c(
                "form",
                {
                  staticClass: "Form",
                  attrs: { id: "form-waster-group" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmitForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "Form-item required" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("WasteGroup.form_name"))),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("customTextInput", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:128|verify_name",
                                expression: "'required|max:128|verify_name'",
                              },
                            ],
                            ref: "WasterGroupName",
                            attrs: {
                              id: "input-waster-group-name",
                              name: "wasteGroupName",
                              "data-vv-as": _vm.$t(
                                "WasteGroup.waste_group_name"
                              ),
                              placeholder: _vm.$t("WasteGroup.form_name"),
                              error: _vm.veeErrors.has("wasteGroupName"),
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                          _c(
                            "Button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.loadingNameControl,
                                  expression: "loadingNameControl",
                                },
                              ],
                              attrs: {
                                variant: "icon loading",
                                id: "btn-waste-group-popup-loading-name",
                                type: "button",
                                tabindex: "-1",
                              },
                            },
                            [_c("Loading", { attrs: { theme: "disable" } })],
                            1
                          ),
                          _c("Button", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isValidateWGName,
                                expression: "isValidateWGName",
                              },
                            ],
                            attrs: {
                              variant: "icon check",
                              id: "btn-waste-group-popup-ok-name",
                              type: "button",
                              tabindex: "-1",
                              iconName: "icon-check",
                              iconClass: "icon-check",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("wasteGroupName"),
                            expression: "veeErrors.has('wasteGroupName')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first("wasteGroupName"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "Form-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("WasteGroup.pos_code"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.posCode,
                              expression: "form.posCode",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:128|verify_poscode",
                              expression: "'max:128|verify_poscode'",
                            },
                          ],
                          staticClass: "txt",
                          class: {
                            "is-danger": _vm.veeErrors.has("wasterPosCode"),
                          },
                          attrs: {
                            name: "wasterPosCode",
                            placeholder: _vm.$t("WasteGroup.pos_code"),
                            "data-vv-as": _vm.$t("WasteGroup.pos_code"),
                            type: "text",
                            "data-vv-delay": _vm.$getConst("INPUT_DELAY_TIME"),
                            id: "input-waster-group-pos-code",
                          },
                          domProps: { value: _vm.form.posCode },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "posCode", $event.target.value)
                            },
                          },
                        }),
                        _c(
                          "Button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.loadingPosCodeControl,
                                expression: "loadingPosCodeControl",
                              },
                            ],
                            attrs: {
                              variant: "icon loading",
                              id: "btn-waste-group-popup-loading-code",
                              type: "button",
                              tabindex: "-1",
                            },
                          },
                          [_c("Loading", { attrs: { theme: "disable" } })],
                          1
                        ),
                        _c("Button", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isValidateWGPosCode,
                              expression: "isValidateWGPosCode",
                            },
                          ],
                          attrs: {
                            variant: "icon check",
                            id: "btn-waste-group-popup-ok-code",
                            type: "button",
                            tabindex: "-1",
                            iconName: "icon-check",
                            iconClass: "icon-check",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("wasterPosCode"),
                          expression: "veeErrors.has('wasterPosCode')",
                        },
                      ],
                      staticClass: "Form-item-help is-danger",
                    },
                    [_vm._v(_vm._s(_vm.veeErrors.first("wasterPosCode")))]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              staticClass: "item",
              attrs: {
                size: "large",
                primary: "",
                variant: "full",
                id: "btn-waster-group-popup-submit",
                type: "submit",
                form: "form-waster-group",
                disabled: _vm.isLoading,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [_vm._v(_vm._s(_vm.$t("WasteGroup.editWasteGroup")))]
                    : [_vm._v(_vm._s(_vm.$t("WasteGroup.addWasteGroup")))],
                ],
                2
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }