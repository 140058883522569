import BaseClass from '@/utils/baseclass'

export default class Wastes extends BaseClass {
  addItem(item) {
    let newItem = new Waste(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.id = item.id
      findItem.wasteGroupId = item.wasteGroupId
      findItem.wasteGroupName = item.wasteGroupName
      findItem.storeId = item.storeId
      findItem.storeName = item.storeName
      findItem.status = item.status
      findItem.wasteDate = item.wasteDate
      findItem.description = item.description
      findItem.code = item.code
      findItem.cost = item.cost
    }
  }
}

export class Waste {
  constructor(item) {
    this.id = item.id
    this.id = item.id
    this.wasteGroupId = item.wasteGroupId
    this.wasteGroupName = item.wasteGroupName
    this.storeId = item.storeId
    this.storeName = item.storeName
    this.status = item.status
    this.wasteDate = item.wasteDate
    this.description = item.description
    this.code = item.code
    this.cost = item.cost
    this.expirationDateControlIsEnabled = item.expirationDateControlIsEnabled
    this.detailList = []
    this.showDetail = false
  }

  addDetails(detailList) {
    this.detailList = []
    detailList.forEach(detail => {
      this.detailList.push(new WasteDetail(detail))
    })
  }

  get isLive() {
    return this.status === 2
  }

  deleteItem(Id) {
    let findIndex = this.findIndex(Id)
    if (findIndex > -1) {
      this.detailList.splice(findIndex, 1)
    }
  }

  findIndex(Id) {
    return this.detailList.findIndex(item => item.id === Id)
  }

  findItem(Id) {
    return this.detailList.find(item => item.id === Id)
  }

  get detailIsEmpty() {
    return this.detailList.length === 0
  }
}

export class WasteDetail {
  constructor(detail) {
    this.id = detail.id
    this.name = detail.name
    this.baseQuantity = detail.baseQuantity
    this.baseUnitId = detail.baseUnitId
    this.baseUnitCode = detail.baseUnitCode
    this.unitCode = detail.unitCode
    this.unitId = detail.unitId
    this.quantity = detail.quantity
    this.recipeHeaderId = detail.recipeHeaderId
    this.wasteHeaderId = detail.wasteHeaderId
    this.stockItemId = detail.stockItemId
    this.totalCost = detail.totalCost
    this.unitCost = detail.unitCost
    this.type = detail.type
    this.unitPrice = detail.unitPrice
    this.description = detail.description
    this.stockOnHandQuantity = detail.stockOnHandQuantity
    this.expirationDateControlIsEnabled = detail.expirationDateControlIsEnabled
  }
}
