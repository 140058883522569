<template lang="pug">
DeleteAlert(
  :item="WasteGroupActive",
  :fields="fields",
  :tableName="$t('WasteGroup.waste_group_delete_title')",
  loading="itemRemoveWasteGroup",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'GroupRemove',

  data () {
    return {
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('WasteGroup.form_name'),
        title: this.$t('WasteGroup.form_name')
      },
      {
        name: 'posCode',
        is_visible: true,
        short_title: this.$t('WasteGroup.pos_code'),
        title: this.$t('WasteGroup.pos_code')
      }]
    }
  },

  computed: {
    ...mapGetters('Waste', ['WasteGroupActive'])
  },

  created () {
    this.RESET_WASTE_GROUP_ACTIVE()
  },
  async mounted () {
    await this.getWasteGroup(this.$route.params.id)
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Waste', [
      'getWasteGroupAll',
      'getWasteGroup',
      'deleteWasteGroup'
    ]),

    ...mapMutations('Waste', ['RESET_WASTE_GROUP_ACTIVE']),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      await this.deleteWasteGroup(this.WasteGroupActive.id).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Deleted', { name: this.WasteGroupActive.name })
          this.notifyShow({ message })
          this.close()
          this.$emit('getList')
        }
      })
    }, 'itemRemoveWasteGroup')
  }
}
</script>
