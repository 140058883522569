import Wastes from '../wastes'
import WasteDocumentDetail from '../waste-detail-document'
import WasteGroups from '../waste-groups'
import WasteForm from '../popup/waste-header'
import WasteFormDetail from '../popup/waste-detail'
import WasteGroupsForm from '../popup/wg-form'
import WasteRemove from '../popup/waste-delete'
import WasteRemoveDetail from '../popup/waste-detail-delete'
import WasteGroupsRemove from '../popup/wg-delete'
import WasteReports from '../waste-report'

export default [
  {
    name: 'Wastes',
    path: '/wastes',
    component: Wastes,
    meta: {
      main_menu: true,
      slug: 'waste',
      page: 'waste',
      category: 'Stock',
      permissionKey: 'WasteMenu'
    },
    children: [
      {
        path: 'new',
        name: 'NewWaste',
        component: WasteForm
      },
      {
        path: 'item/:id/edit',
        name: 'EditWaste',
        component: WasteForm,
        meta: {
          status: 'edit'
        }
      },
      {
        path: 'item/:id/delete',
        name: 'DeleteWaste',
        component: WasteRemove
      },
      {
        path: 'item/:id/newDetail',
        name: 'NewWasteDetail',
        component: WasteFormDetail
      },
      {
        path: 'item/:id/:detailId/editDetail',
        name: 'EditWasteDetail',
        component: WasteFormDetail,
        meta: {
          status: 'edit'
        }
      },
      {
        path: 'item/:id/:detailId/delete',
        name: 'DeleteWasteDetail',
        component: WasteRemoveDetail
      }
    ]
  },
  {
    name: 'WasteGroups',
    path: '/wastes/waste-groups',
    component: WasteGroups,
    meta: {
      page: 'waste'
    },
    children: [
      {
        path: 'new',
        name: 'NewWasterGroup',
        component: WasteGroupsForm
      },
      {
        path: 'item/:id/edit',
        name: 'EditWasterGroup',
        meta: {
          status: 'edit'
        },
        component: WasteGroupsForm
      },
      {
        path: 'item/:id/delete',
        name: 'DeleteWasterGroup',
        component: WasteGroupsRemove
      }
    ]
  },
  {
    path: '/wastes/report',
    name: 'WasteReports',
    component: WasteReports,
    meta: {
      permissionKey: 'WasteMenu',
      page: 'waste'
    }
  },
  {
    path: '/waste/detail/:id/:documentNumber',
    name: 'WasteDocumentDetail',
    component: WasteDocumentDetail,
    meta: {
      tabPermissionKey: 'WasteMenu',
      page: 'waste'
    }
  }
]
