<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :tableName="$t('WasteGroup.waste_group_delete_title')",
  loading="deleteWasteHeader",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'GroupRemove',

  data () {
    return {
      item: {},
      fields: [{
        name: 'storeName',
        is_visible: true,
        short_title: this.$t('Waste.form_store'),
        title: this.$t('Waste.form_store')
      },
      {
        name: 'wasteGroupName',
        is_visible: true,
        short_title: this.$t('Waste.waste_group'),
        title: this.$t('Waste.waste_group')
      },
      {
        name: 'wasteDate',
        is_visible: true,
        short_title: this.$t('Waste.wasteDate'),
        title: this.$t('Waste.wasteDate')
      }]
    }
  },

  async mounted () {
    const res = await this.getWasteHeader(this.$route.params.id)
    this.item = {
      ...res.data.wasteHeader,
      wasteDate: moment(res.data.wasteHeader.wasteDate).format('DD.MM.YYYY')
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Waste', [
      'getWasteHeaderAll',
      'getWasteHeader',
      'deleteWaste'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      await this.deleteWaste(this.item.id).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Deleted', { name: this.item.storeName })
          this.notifyShow({ message })
          this.$emit('getList')
          this.close()
        }
      })
    }, 'deleteWasteHeader')
  }
}
</script>
