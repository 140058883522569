var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _vm._v(_vm._s(_vm.$t("Waste.waste_detail"))),
      ]),
      _c("template", { slot: "detail" }, [
        _c("div", { staticClass: "Popup-waste-info row" }, [
          _c("div", { staticClass: "ff-mono" }, [
            _vm._v(_vm._s(_vm.wasteHeaderInfo.code)),
          ]),
          !_vm.isEdit
            ? _c("div", { staticClass: "PopupHeader" }, [
                _c(
                  "div",
                  { staticClass: "tab" },
                  [
                    _c(
                      "Button",
                      {
                        class: { isSelected: _vm.selectedDetailType === 0 },
                        attrs: {
                          size: "small",
                          id: "btn-waste-popup-tab-header-1",
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.tabChange(0)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("Waste.popupAdd_Header_StockItem"))
                        ),
                      ]
                    ),
                    _c(
                      "Button",
                      {
                        class: { isSelected: _vm.selectedDetailType === 1 },
                        attrs: {
                          size: "small",
                          id: "btn-waste-popup-tab-header-2",
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.tabChange(1)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Waste.popupAdd_Header_Recipe")))]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.isLoadingForm ? _c("Loading") : _vm._e(),
          !_vm.isLoadingForm
            ? _c(
                "form",
                {
                  staticClass: "Form",
                  attrs: { id: "form-waste-detail" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmitFormWaste.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectedDetailType === 0,
                          expression: "selectedDetailType === 0",
                        },
                      ],
                      staticClass: "Form-item required",
                    },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Waste.popupAdd_Header_StockItem"))
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("custom-search", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  required: _vm.selectedDetailType === 0,
                                },
                                expression:
                                  "{'required' : selectedDetailType === 0}",
                              },
                            ],
                            ref: "firstInput",
                            attrs: {
                              name: "stockItem",
                              "component-name": "waste-detail-stock-item",
                              "data-vv-as": _vm.$t(
                                "Despatches.detail_Popup_FormField_StockItemName"
                              ),
                              inputClass: {
                                "is-danger": _vm.veeErrors.has("stockItem"),
                                verify_inventory: true,
                              },
                              "on-search": _vm.getOptions,
                              options: _vm.selectOptions,
                              disabled: _vm.isEdit,
                              label: "name",
                              placeholder: _vm.$t(
                                "Despatches.detail_Popup_FormField_StockItemName_Placeholder"
                              ),
                              directSelectOption: _vm.directSelectOption,
                            },
                            on: { input: _vm.fetchStockItems },
                            model: {
                              value: _vm.form.stockItem,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "stockItem", $$v)
                              },
                              expression: "form.stockItem",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("stockItem"),
                            expression: "veeErrors.has('stockItem')",
                          },
                        ],
                        attrs: { errorName: _vm.veeErrors.first("stockItem") },
                      }),
                      _vm.form.stockItem
                        ? _c("div", { staticClass: "Form-item-info" }, [
                            _c("div", [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(_vm._s(_vm.$t("Waste.detail_form_tb"))),
                              ]),
                              _vm.selectedStock.unitCode
                                ? _c("div", { staticClass: "value" }, [
                                    _vm._v(
                                      _vm._s(_vm.selectedStock.baseUnitName)
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("div", [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(_vm._s(_vm.$t("Waste.averagePrice"))),
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      _vm.selectedStock.baseUnitPrice
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.selectedDetailType === 1
                    ? _c(
                        "div",
                        { staticClass: "Form-item required recipeField" },
                        [
                          _c("label", { staticClass: "Form-item-label" }, [
                            _vm._v(
                              _vm._s(_vm.$t("Waste.popupAdd_Header_Recipe"))
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "control" },
                            [
                              _c("custom-search", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      required: _vm.selectedDetailType === 1,
                                    },
                                    expression:
                                      "{'required' : selectedDetailType === 1}",
                                  },
                                ],
                                ref: "firstInputRecipe",
                                attrs: {
                                  "component-name": "waste-detail-recipe",
                                  name: "recipe",
                                  "data-vv-as": _vm.$t(
                                    "Waste.popupAdd_Header_Recipe"
                                  ),
                                  inputClass: {
                                    "is-danger": _vm.veeErrors.has("recipe"),
                                    verify_inventory: true,
                                  },
                                  "on-search": _vm.getRecipeOptions,
                                  options: _vm.formNewRecipeSelectOptions,
                                  disabled: _vm.isEdit,
                                  label: "name",
                                  placeholder: _vm.$t(
                                    "Despatches.detail_Popup_FormField_StockItemName_Placeholder"
                                  ),
                                  directSelectOption:
                                    _vm.recipeDirectSelectOption,
                                },
                                on: { afterSelected: _vm.setRecipe },
                                model: {
                                  value: _vm.form.recipe,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "recipe", $$v)
                                  },
                                  expression: "form.recipe",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("recipe"),
                                expression: "veeErrors.has('recipe')",
                              },
                            ],
                            attrs: { errorName: _vm.veeErrors.first("recipe") },
                          }),
                          _vm.form.recipe
                            ? _c("div", { staticClass: "Form-item-info" }, [
                                _c("div", [
                                  _c("div", { staticClass: "text" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("Waste.detail_form_cost"))
                                    ),
                                  ]),
                                  _c("div", { staticClass: "value" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          _vm.selectedStock.unitPrice
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "Form-item required" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Despatches.detail_Popup_FormField_Quantity")
                          )
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("customNumberInput", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  required: true,
                                  decimal: _vm.$getConst(
                                    "DECIMAL_FOR_QUANTITY"
                                  ),
                                  is_not: 0,
                                  max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                                },
                                expression:
                                  "{required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), is_not:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                              },
                            ],
                            attrs: {
                              id: "waste-detail-number-input",
                              name: "quantity",
                              "data-vv-as": _vm.$t(
                                "Despatches.detail_Popup_FormField_Quantity"
                              ),
                              error: _vm.veeErrors.has("quantity"),
                            },
                            model: {
                              value: _vm.form.quantity,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "quantity", $$v)
                              },
                              expression: "form.quantity",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("quantity"),
                            expression: "veeErrors.has('quantity')",
                          },
                        ],
                        attrs: { errorName: _vm.veeErrors.first("quantity") },
                      }),
                      _vm.form.stockItem || _vm.form.recipe
                        ? _c("div", { staticClass: "Form-item-info" }, [
                            _c("div", [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "Waste.detail_TableColumn_StockOnHandQuantity"
                                    )
                                  )
                                ),
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.stockItemInfo.baseUnitQuantity !== null
                                      ? _vm.stockItemInfo.baseUnitQuantity
                                      : "-"
                                  )
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Despatches.detail_Popup_FormField_BaseUnit")
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                        _c("customSelectInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|is_not:-1",
                              expression: "'required|is_not:-1'",
                            },
                          ],
                          attrs: {
                            name: "unitId",
                            optionData: _vm.filteredUnitList,
                            "data-vv-as": _vm.$t(
                              "Despatches.detail_Popup_FormField_BaseUnit"
                            ),
                            error: _vm.veeErrors.has("unitId"),
                            id: "select-Waste-popup-baseunit",
                            optionIdName: "option-Waste-popup-baseunit",
                            disabled: _vm.isEdit,
                          },
                          on: { change: _vm.onStockChange },
                          model: {
                            value: _vm.form.unitId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "unitId", $$v)
                            },
                            expression: "form.unitId",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("unitId"),
                              expression: "veeErrors.has('unitId')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("unitId") },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "Form-item" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("Waste.form_description"))),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("customTextareaInput", {
                            staticClass: "txt",
                            attrs: {
                              id: "waste-header-input",
                              name: "description",
                              "data-vv-as": _vm.$t("Waste.form_description"),
                              error: _vm.veeErrors.has("description"),
                            },
                            model: {
                              value: _vm.form.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "description", $$v)
                              },
                              expression: "form.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("description"),
                            expression: "veeErrors.has('description')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first("description"),
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.isEdit
            ? _c(
                "div",
                { staticClass: "add-other-items-checkbox-container" },
                [
                  _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-despatch-detail-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t("Waste.saveAndNewKey"),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "Button",
            {
              staticClass: "item",
              attrs: {
                size: "medium",
                primary: "",
                variant: "full",
                id: "btn-Waste-popup-submit",
                type: "submit",
                form: "form-waste-detail",
                disabled: _vm.isLoading || _vm.inventoryValidation,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [_vm._v(_vm._s(_vm.$t("Waste.updateWasteDetail")))]
                    : [_vm._v(_vm._s(_vm.$t("Waste.addWasteDetail")))],
                ],
                2
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }