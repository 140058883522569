import HTTP from '@/utils/http'
import { isEmpty } from '@/utils/baseOperations'
import requestHandler from '@/utils/requestHandler'
import { getPaginationNumber } from '@/utils/pagination'
// model
import Wastes from '../model'
import BatchList from '../model/waste-batch'

export default {
  namespaced: true,
  state: {
    Waste: new Wastes(),
    Batch: new BatchList(),
    WasteGroup: [],
    WasteGroupActive: [],
    Page: {
      number: 1,
      size: 20,
      total: 0
    },
    pageHeaderLinks: [
      'Wastes',
      'WasteGroups',
      'WasteReports'
    ]
  },
  getters: {
    pageHeaderLinks: state => state.pageHeaderLinks,
    isEmptyWasteList: state => isEmpty(state.Waste.list),
    isEmptyWasteGroups: state => isEmpty(state.WasteGroup),
    Waste: state => state.Waste,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    }),
    WasteGroupList: state => state.WasteGroup,
    WasteGroupActive: state => state.WasteGroupActive,
    Batch: state => state.Batch
  },
  actions: {

    async getWasteHeaderAll({state, commit}, payload) {
      payload.pageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const results = await requestHandler({
        title: 'getWasteHeaderAll',
        methodName: 'getWasteHeaderAll',
        method: () => {
          return HTTP.post('Waste/header/all', payload)
        },
        success: result => {
          commit('SET_WASTE_LIST', result.data)
          return result
        }
      })
      return results
    },

    async getWasteHeader({commit}, Id) {
      const results = await requestHandler({
        title: 'Get_Waste_Header',
        methodName: 'Get_Waste_Header',
        method: () => {
          return HTTP.get('Waste', {
            params: { Id }
          })
        },
        success: result => result
      })

      return results
    },

    async getWasteGroupAll({ commit }, payload) {
      const results = await requestHandler({
        title: 'Get_Waste_Group_All',
        methodName: 'Get_Waste_Group_All',
        method: () => {
          return HTTP.post('WasteGroup/all', payload)
        },
        success: result => {
          commit('SET_WASTE_GROUP_LIST', result.data.wasteGroupList)
          return result
        }
      })

      return results
    },

    async getWasteGroup({commit}, Id) {
      const results = await requestHandler({
        title: 'Get_Waste_Group',
        methodName: 'Get_Waste_Group',
        method: () => {
          return HTTP.get('WasteGroup', {
            params: { Id }
          })
        },
        success: result => {
          commit('SET_WASTE_GROUP_ACTIVE', result.data.wasteGroup)
        }
      })

      return results
    },

    async getWasteDetail({commit}, Id) {
      const results = await requestHandler({
        title: 'Get_Waste_Detail',
        methodName: 'Get_Waste_Detail',
        method: () => {
          return HTTP.get('Waste/Detail', {
            params: { Id }
          })
        },
        success: result => result
      })

      return results
    },

    async createWasteHeader({commit, dispatch}, data) {
      const results = await requestHandler({
        title: 'Create Waste Header',
        methodName: 'Create_Waste_header',
        method: () => {
          return HTTP.post('Waste/header', data)
        },
        success: result => result
      })

      return results
    },

    async createWasteHeaderDetail({commit, dispatch}, data) {
      const results = await requestHandler({
        title: 'Create Waste Header Detail',
        methodName: 'Create_Waste_Header_detail',
        method: () => {
          return HTTP.post('Waste/detail', data)
        },
        success: result => result
      })

      return results
    },

    async updateWasteHeader({commit, dispatch}, data) {
      const results = await requestHandler({
        title: 'Update Waste Header',
        methodName: 'Update_Waste_header',
        method: () => {
          return HTTP.put('Waste/header', data)
        },
        success: result => result
      })

      return results
    },

    async updateWasteHeaderDetail({commit, dispatch}, data) {
      const results = await requestHandler({
        title: 'Update Waste Header Detail',
        methodName: 'Update_Waste_Header_detail',
        method: () => {
          return HTTP.put('Waste/detail', data)
        },
        success: result => result
      })

      return results
    },

    async createWasteGroup({commit, dispatch}, data) {
      const results = await requestHandler({
        title: 'Create Waste Groups',
        methodName: 'Create_Waste_Groups',
        method: () => {
          return HTTP.post('WasteGroup', data)
        },
        success: result => {
          return result
        }
      })

      return results
    },

    async updateWasteGroup({commit, dispatch}, data) {
      const results = await requestHandler({
        title: 'update Waste Groups',
        methodName: 'Update_Waste_Groups',
        method: () => {
          return HTTP.put('WasteGroup', data)
        },
        success: result => {
          return result
        }
      })

      return results
    },

    async updateFinalize({commit, dispatch}, data) {
      const results = await requestHandler({
        title: 'update Waste Finalize',
        methodName: 'Update_Waste_Finalize',
        method: () => {
          return HTTP.put('Waste/finalize', data)
        },
        success: result => result
      })

      return results
    },

    async deleteWasteGroup({ commit }, Id) {
      const loadingName = 'deleteWasteGroup'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.delete('WasteGroup', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async deleteWaste({ commit }, Id) {
      const loadingName = 'deleteWaste'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.delete('Waste', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async deleteWasteDetail({ commit }, Id) {
      const loadingName = 'deleteWasteDetail'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.delete('Waste/detail', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async checkWasteGroupName({ commit }, data) {
      const params = data.id ? `id=${data.id}&name=${data.name}` : `name=${data.name}`
      const results = await requestHandler({
        title: 'Check Waste Group Name',
        methodName: 'Check_Waste_Group_Name',
        method: () => {
          return HTTP.get(`WasteGroup/check/name?${params}`)
        },
        success: result => result
      })

      return results
    },

    async checkWasteGroupPosCode({ commit }, data) {
      const params = data.id ? `id=${data.id}&poscode=${data.PosCode}` : `poscode=${data.PosCode}`
      const results = await requestHandler({
        title: 'Check Waste Group Name',
        methodName: 'Check_Waste_Group_Name',
        method: () => {
          return HTTP.get(`WasteGroup/check/poscode?${params}`)
        },
        success: result => result
      })
      return results
    },

    async getWasteBatchDetail({ commit }, payload) {
      const results = await requestHandler({
        title: 'getWasteBatchDetail',
        methodName: 'getWasteBatchDetail',
        method: () => {
          return HTTP.post('Batch/waste/all', payload)
        },
        success: result => result
      })
      return results
    },

    async updateBatchQuantity({commit, dispatch}, payload) {
      let results = await requestHandler({
        title: 'updateBatchQuantity',
        methodName: 'updateBatchQuantity',
        method: () => {
          return HTTP.put('Batch/waste/quantity', payload)
        },
        success: result => result
      })
      return results
    },
    async getWasteBatchData({ commit }, payload) {
      const results = await requestHandler({
        title: 'getWasteBatchData',
        methodName: 'getWasteBatchData',
        method: () => {
          return HTTP.post('Waste/detail/batch', payload)
        },
        success: result => {
          commit('SET_BATCH_LIST', result.data)
          return result
        }
      })
      return results
    },
    async validateBatchDetails({commit, dispatch}, payload) {
      let results = await requestHandler({
        title: 'validateBatchDetails',
        methodName: 'validateBatchDetails',
        method: () => {
          return HTTP.post('Waste/detail/batch/validate', payload)
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {

    RESET(state) {
      state.Waste = new Wastes()
      state.Page = {
        number: 1,
        size: 20,
        total: 0
      }
    },

    SET_WASTE_LIST(state, data) {
      state.Waste.addItems(data.wasteHeaderList)
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },

    HIDE_OTHERS_DETAILS(state, wasteHeader) {
      const others = state.Waste.list.filter(waste => waste.id !== wasteHeader.id)
      others.forEach(otherTransfer => {
        otherTransfer.showDetail = false
      })
    },
    UPDATE_WASTE_HEADER(state, data) {
      let waste = state.Waste.findItem(data.wasteHeader.id)
      waste.cost = data.wasteHeader.cost
      waste.addDetails(data.wasteDetailList)
    },
    SHOW_DETAIL(state, data) {
      let waste = state.Waste.findItem(data.wasteHeader.id)
      waste.addDetails(data.wasteDetailList)
      waste.showDetail = true
    },
    HIDE_DETAIL(state, wasteHeader) {
      wasteHeader.showDetail = false
    },

    UPDATE_WASTE_ITEM(state, item) {
      state.Waste.updateItem(item)
    },

    RESET_WASTE_GROUP_ACTIVE(state) {
      state.WasteGroupActive = null
    },
    SET_WASTE_GROUP_ACTIVE(state, list) {
      state.WasteGroupActive = list
    },
    SET_WASTE_GROUP_LIST(state, list) {
      state.WasteGroup = list
    },
    SET_BATCH_LIST(state, data) {
      state.Batch.addItems(data.wasteDetailList)
    },
    SHOW_BATCH_DETAIL(state, data) {
      let batchDetail = state.Batch.findItem(data.id)
      if (batchDetail) {
        batchDetail.addDetails(data.batchList)
        batchDetail.showDetail = true
      }
    },
    HIDE_BATCH_DETAIL(state, batch) {
      batch.showDetail = false
    },
    SET_EXPIRATION_DATE_CONTROL(state, data) {
      data.waste.expirationDateControlIsEnabled = data.expirationDateControl
    }
  }
}
