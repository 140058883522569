<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :tableName="$t('WasteGroup.waste_group_delete_title')",
  loading="deleteWasteDetail",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'GroupRemove',

  data () {
    return {
      item: {},
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('Waste.waste_group'),
        title: this.$t('Waste.waste_group')
      },
      {
        name: 'quantity',
        is_visible: true,
        short_title: this.$t('Waste.wasteDate'),
        title: this.$t('Waste.wasteDate')
      }]
    }
  },

  async mounted () {
    const res = await this.getWasteDetail(this.$route.params.detailId)
    this.item = {
      ...res.data.wasteDetail
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapMutations('Waste', ['SHOW_DETAIL', 'UPDATE_WASTE_ITEM']),

    ...mapActions('Waste', [
      'getWasteHeader',
      'getWasteDetail',
      'deleteWasteDetail'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteWasteDetail(this.item.id)
      if (res) {
        const message = this.$t('Global.notification_Deleted', { name: this.item.name })
        this.notifyShow({ message })
        const list = await this.getWasteHeader(this.$route.params.id)
        this.UPDATE_WASTE_ITEM(list.data.wasteHeader)
        this.SHOW_DETAIL(list.data)
        this.close()
      }
    }, 'deleteWasteDetail')
  }
}
</script>
