<template lang="pug">
Popup

    template(slot="title")
        template(v-if="isEdit")  {{ $t('Waste.waste_form_edit_title') }}
        template(v-else) {{ $t('Waste.waste_form_title') }}

    template(slot="content")
      Loading(v-if="isLoadingForm")
      form.Form(
      v-if="!isLoadingForm"
      id="form-waste"
      @submit.prevent="onSubmitForm")

        .Form-item.required
            label.Form-item-label
              | {{ $t('Waste.form_store') }}
            .control.form-item-select
              custom-search(
                ref="firstInput"
                component-name="waste-from-store-name"
                name="storeName",
                v-model="form.storeId"
                :data-vv-as="$t('Waste.form_store')"
                v-validate="'required|is_not:-1|verify_inventory'"
                :inputClass="{ 'is-danger': !inventoryValidation && veeErrors.has('storeName') }",
                :on-search="getOptionsStore",
                :options="selectMiniStoreList",
                label="name",
                :searchAll="true"
                :withDebounce="false"
                :loading="isSearchStoreLists"
                @input="inputSelection"
                @resetOptions="resetOptions"
              )
              showValidateError(
                v-show="inventoryValidation"
                :errorName="$t('Inventory.closedInventoryError')"
              )
        .Form-item.required
          label.Form-item-label
            | {{ $t('Waste.waste_group') }}
          .control.form-item-select
            Icon(v-show="!isLoadingWasteGroup" name="icon-down-arrow")
            customSelectInput(
              name="wasteGroup"
              :optionData="WasteGroupList"
              v-model="form.wasteGroupId"
              :data-vv-as="$t('Waste.waste_group')"
              :error="veeErrors.has('wasteGroup')"
              v-validate="'required|is_not:-1'"
              id="select-waste-from-waste-group"
              optionIdName="option-waste-popup-group-name"
              :disabled="isLoadingWasteGroup || !form.storeId"
            )

            Button(
              variant="icon loading",
              id="select-waste-from-waste-group-loading"
              type="button",
              tabindex="-1",
              v-show="isLoadingWasteGroup")
                Loading(theme="disable")

          showValidateError(
            v-show="veeErrors.has('wasteGroup')"
            :errorName="veeErrors.first('wasteGroup')"
          )

        .Form-item.required
          label.Form-item-label {{ $t('Waste.wasteDate') }}
          .control.form-item-select

            CustomDatepicker(
              id="waste-header-datepicker"
              name="wasteDate",
              v-model="form.wasteDate",
              :class="{ 'is-danger': veeErrors.has('wasteDate') }"
              :data-vv-as="$t('Waste.wasteDate')",
              v-validate="'required|verify_inventory'",
              pickerType="manuel",
              inputIconRight="icon-datepicker-big"
              inputIcon="",
              :disabledStartDate="disableDate")

          p.Form-item-help.is-danger(
          v-show="inventoryValidation"
          ) {{ $t('Inventory.closedInventoryError') }}

        .Form-item(v-if="!isEdit" :class="isOnlyListUsage ? 'required' : '' ")
          label.Form-item-label {{ $t('Waste.Form_List') }}
          .control
            custom-search(
              component-name="select-waste-from-list"
              v-model="form.list"
              :data-vv-as="$t('Waste.Form_List') "
              :on-search="getOptions"
              :options="selectOptions"
              v-validate="{required: isOnlyListUsage && selectOptions.length < 1}"
              name="custom-list-search"
              label="name"
              :searchAll="true"
              :placeholder="$t('Waste.Form_List_Placeholder')"
            )
          showValidateError(
            v-show="veeErrors.has('custom-list-search') && isOnlyListUsage"
            :errorName="veeErrors.first('custom-list-search')"
          )

          .selected-items
            Button(
              size="small",
              v-for="(item,index) in wasteList",
              :key="index",
              seashell,
              :id="`selected-stock-item-${index}`"
              @click="removeListItem(item)"
            )
              span {{ item.name }}
              Icon.m-left-5(
                name="icon-popup-close"
                :id="`selected-stock-item-delete-${index}`"
              )
        .Form-item
          label.Form-item-label {{ $t('Waste.form_description') }}
          .control
            customTextareaInput.txt(
              id="waste-header-input"
              name="description"
              :data-vv-as="$t('Waste.form_description')"
              v-model="form.description"
              :error="veeErrors.has('description')"
            )

          showValidateError(
              v-show="veeErrors.has('description')"
              :errorName="veeErrors.first('description')"
            )

    template(slot="footer")

      Button.item(
      size="medium"
      primary
      variant="full"
      id="btn-transfer-popup-submit"
      type="submit",
      form="form-waste",
      :disabled="isLoading || inventoryValidation || isLoadingWasteGroup")
        span(v-show="!isLoading")
          template(v-if="isEdit") {{ $t('Waste.waste_form_edit_title') }}
          template(v-else) {{ $t('Waste.waste_form_title') }}
        Loading(theme="disable", v-show="isLoading")
</template>

<script>

import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import CheckInventoryMixin from '@/mixins/validateInventory'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'WasteForm',
  mixins: [CheckInventoryMixin],
  data () {
    return {
      disableDate: {
        from: new Date()
      },
      form: {
        storeId: {
          id: '-1',
          name: ''
        },
        wasteDate: new Date(),
        wasteGroupId: '-1',
        description: null,
        list: null
      },
      wasteList: [],
      selectOptions: [],
      selectMiniStoreList: [],
      isOnlyListUsage: false,
      dates: [ moment(1, 'DD').hour(0).minute(0)._d, moment().hour(0).minute(0)._d ]
    }
  },
  computed: {
    ...mapGetters('Stores', [
      'storeList'
    ]),

    ...mapGetters('Waste', [
      'WasteGroupList',
      'Page'
    ]),

    ...mapGetters('Settings', [
      'UserInfo',
      'permissions'
    ]),

    checkInventoryParams () {
      /*
      *  CheckInventoryMixin için Inventory Validator Parametreleri tanımlandı.
      */
      return {
        storeId: this.form.storeId.id,
        inventoryDate: moment(this.form.wasteDate).format('YYYY-MM-DD')
      }
    },

    isEdit () {
      return this.$route.meta.status === 'edit'
    },

    isLoading () {
      return this.$wait.is(['Create_Waste_header', 'updateWasteHeader', 'onSubmitFormWasteHeader'])
    },
    isLoadingForm () {
      return this.$wait.is(['getStoreList', 'fetchUser', 'Get_Waste_Header'])
    },
    isLoadingWasteGroup () {
      return this.$wait.is(['Get_Waste_Group_All'])
    },
    isSearchStoreLists () {
      return this.$wait.is('fetchStoreByName')
    },
  },

  async mounted () {
    await this.getStoreList({ pageNumber: 1, pageSize: 9999 })
    if (this.isEdit) {
      const res = await this.getWasteHeader(this.$route.params.id)
      this.form = { ...res.data.wasteHeader }
      this.form.storeId = { id: res.data.wasteHeader.storeId, name: res.data.wasteHeader.storeName}
      this.getWasteGroups(false)
    } else {
      this.form.storeId = { id: this.UserInfo.defaultStoreId, name: this.UserInfo.defaultStoreName} 
      this.getWasteGroups()
      this.permissions.find(i => {
        if (i.permission === 221) this.isOnlyListUsage = true
      })
    }
  },

  methods: {
    ...mapActions('Stores', [
      'getStoreList',
      'fetchStoreByName'
    ]),

    ...mapActions('Waste', [
      'getWasteGroupAll',
      'getWasteHeader',
      'createWasteHeader',
      'updateWasteHeader',
      'getWasteHeaderAll'
    ]),

    ...mapActions('ListsWaste', ['searchWasteListHeader']),

    ...mapActions('Transfer', ['checkInventoryForStore']),

    ...mapActions('Settings', [
      'fetchUser'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapMutations('Waste', [
      'HIDE_OTHERS_DETAILS',
      'SHOW_DETAIL'
    ]),

    formReset () {
      this.form.storeId = { id: '-1', name: ''}
      this.form.description = null
      this.form.wasteDate = new Date()
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    async getWasteGroups (resetWasteGroup = true) {
      if (resetWasteGroup) this.form.wasteGroupId = '-1'
      if (this.form.storeId.id === '-1' || !this.form.storeId) return
      const payload = {
        storeGroupList: [this.storeList.find(item => item.id === this.form.storeId.id).storeGroupId],
        pageSize: 999999999
      }
      await this.getWasteGroupAll(payload)
    },

    async addNewWaste () {
      const data = {
        ...this.form,
        wasteDate: moment(this.form.wasteDate).format('YYYY-MM-DD'),
        wasteListHeaderIds: this.wasteList.map(_ => _.id)
      }
      data.storeId = data.storeId.id
      const res = await this.createWasteHeader(data)
      if (res) {
        this.$emit('showDrafts')
        this.close()
        const message = this.$t('Waste.add_success_message')
        this.notifyShow({ message })
        this.$emit('getList')
        if (this.wasteList.length === 0) {
          this.$router.push({
            name: 'NewWasteDetail',
            params: {
              id: res.data.id
            }
          })
        } else {
          const list = await this.getWasteHeader(res.data.id)
          this.SHOW_DETAIL(list.data)
        }
      }
    },

    async EditWaste () {
      const data = {
        ...this.form,
        wasteDate: moment(this.form.wasteDate).format('YYYY-MM-DD')
      }
      data.storeId = data.storeId.id
      const res = await this.updateWasteHeader(data)
      if (res) {
        this.close()
        const message = this.$t('Waste.edit_success_message')
        this.notifyShow({ message })
        this.$emit('getList')
      }
    },

    getOptions (search) {
      this.searchWasteListHeader(search)
        .then(res => {
          this.selectOptions = res.data.wasteListHeaderList
        })
    },

    getOptionsStore (search) {
      this.fetchStoreByName({name: search})
        .then(res => {
          if (res) {
            this.selectMiniStoreList = res.data.items
          }
        })
    },
    inputSelection (data) {
      if (data.length !== 0) {
        this.form.storeId.id = data.id
        this.form.storeId.name = data.name
      }
    },

    async resetOptions () {
      this.selectMiniStoreList = []
      this.getOptionsStore('')
    },

    removeListItem (item) {
      this.wasteList.splice(this.wasteList.indexOf(item), 1)
    },

    onSubmitForm: vueWaitLoader(async function () {
      const res = await this.$validator.validateAll()
      if (!res || this.inventoryValidation) return

      if (this.isEdit) await this.EditWaste()
      else await this.addNewWaste()
    }, 'onSubmitFormWasteHeader')
  },

  watch: {
    async 'form.list' (value) {
      if (!value) return
      else if (!this.wasteList.some(item => item.id === value.id)) {
        this.wasteList.push(value)
      }
      this.$nextTick(() => {
        this.form.list = null
      })
    },
    'form.storeId' () {
      this.getWasteGroups(false)
    }
  }
}
</script>

<style lang="scss" scoped>

  .selected-items {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .btn {
      width: auto;
      height: 34px;
      margin-right: 10px;
      margin-top: 10px;
    }
  }
</style>
