<template lang="pug">
Popup

    template(slot="title")
        template(v-if="isEdit")  {{ $t('WasteGroup.editWasteGroup') }}
        template(v-else) {{ $t('WasteGroup.addWasteGroup') }}

    template(slot="content")
      Loading(v-if="isLoadingForm")
      form.Form(
      v-if="!isLoadingForm"
      id="form-waster-group"
      @submit.prevent="onSubmitForm")

        .Form-item.required
          label.Form-item-label {{ $t('WasteGroup.form_name') }}
          .control
            customTextInput(
              id="input-waster-group-name"
              ref="WasterGroupName"
              v-model="form.name"
              v-validate="'required|max:128|verify_name'"
              name="wasteGroupName"
              :data-vv-as="$t('WasteGroup.waste_group_name')"
              :placeholder="$t('WasteGroup.form_name')"
              :error="veeErrors.has('wasteGroupName')"
            )

            Button(
            variant="icon loading"
            id="btn-waste-group-popup-loading-name"
            type="button",
            tabindex="-1",
            v-show="loadingNameControl")
              Loading(theme="disable")
            Button(
            variant="icon check"
            id="btn-waste-group-popup-ok-name"
            type="button",
            tabindex="-1",
            iconName="icon-check"
            iconClass="icon-check"
            v-show="isValidateWGName")

          showValidateError(
            v-show="veeErrors.has('wasteGroupName')"
            :errorName="veeErrors.first('wasteGroupName')"
          )

        .Form-item
          label.Form-item-label {{ $t('WasteGroup.pos_code') }}
          .control
            input.txt(
                  name="wasterPosCode"
                  :placeholder="$t('WasteGroup.pos_code')"
                  :data-vv-as="$t('WasteGroup.pos_code')"
                  v-model="form.posCode"
                  v-validate="'max:128|verify_poscode'"
                  :class="{ 'is-danger': veeErrors.has('wasterPosCode') }"
                  type="text"
                  :data-vv-delay="$getConst('INPUT_DELAY_TIME')"
                  id="input-waster-group-pos-code")

            Button(
            variant="icon loading"
            id="btn-waste-group-popup-loading-code"
            type="button",
            tabindex="-1",
            v-show="loadingPosCodeControl")
              Loading(theme="disable")
            Button(
            variant="icon check"
            id="btn-waste-group-popup-ok-code"
            type="button",
            tabindex="-1",
            iconName="icon-check"
            iconClass="icon-check"
            v-show="isValidateWGPosCode")

        p.Form-item-help.is-danger(
          v-show="veeErrors.has('wasterPosCode')"
          ) {{ veeErrors.first('wasterPosCode') }}
    template(slot="footer")

      Button.item(
      size="large"
      primary
      variant="full"
      id="btn-waster-group-popup-submit"
      type="submit",
      form="form-waster-group",
      :disabled="isLoading")
        span(v-show="!isLoading")
          template(v-if="isEdit") {{ $t('WasteGroup.editWasteGroup') }}
          template(v-else) {{ $t('WasteGroup.addWasteGroup') }}
        Loading(theme="disable", v-show="isLoading")
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Validator, mapFields } from 'vee-validate'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'WasteGroupForm',

  data () {
    return {
      form: {
        name: null,
        posCode: null
      }
    }
  },

  computed: {

    isEdit () {
      return this.$route.meta.status === 'edit'
    },

    ...mapFields({
      wasteGroupName: 'wasteGroupName',
      wasterPosCode: 'wasterPosCode'
    }),

    ...mapGetters('Waste', [
      'WasteGroupActive'
    ]),

    loadingNameControl () {
      return this.wasteGroupName.pending
    },

    loadingPosCodeControl () {
      return this.wasterPosCode.pending
    },

    isValidateWGName () {
      return this.wasteGroupName.valid &&
              !this.loadingNameControl
    },

    isValidateWGPosCode () {
      return this.wasterPosCode.valid &&
              !this.loadingPosCodeControl &&
              this.form.posCode !== null
    },

    isLoading () {
      return this.$wait.is(['Create_Waste_Groups', 'submitFormWasteGroup'])
    },
    isLoadingForm () {
      return this.$wait.is(['Get_Waste_Group'])
    }
  },

  created () {
    Validator.extend('verify_name', {
      // Custom validation message
      getMessage: () => this.$t('WasteGroup.verify_error_message', { field: this.$t('WasteGroup.waste_group') }),
      // Custom validation rule
      validate: (value) => new Promise(resolve => {
        this.checkWasteGroupName({
          id: this.WasteGroupActive ? this.WasteGroupActive.id : null,
          name: value
        })
          .then(res => {
            resolve({
              valid: res.data.isAvailable
            })
          })
      })
    })

    Validator.extend('verify_poscode', {
      // Custom validation message
      getMessage: () => this.$t('WasteGroup.verify_error_message', { field: this.$t('WasteGroup.pos_code') }),
      // Custom validation rule
      validate: (value) => new Promise(resolve => {
        if (this.isEdit && !this.form.posCode) { return }
        this.checkWasteGroupPosCode({
          id: this.WasteGroupActive ? this.WasteGroupActive.id : null,
          PosCode: value
        })
          .then(res => {
            resolve({
              valid: res.data.isAvailable
            })
          })
      })
    })
  },

  async mounted () {
    this.RESET_WASTE_GROUP_ACTIVE()
    this.formFocus()
    if (this.isEdit) {
      await this.getWasteGroup(this.$route.params.id)
      this.form = {
        ...this.WasteGroupActive
      }
    }
  },

  methods: {
    ...mapMutations('Waste', ['RESET_WASTE_GROUP_ACTIVE']),
    ...mapActions('Waste', [
      'createWasteGroup',
      'updateWasteGroup',
      'checkWasteGroupName',
      'checkWasteGroupPosCode',
      'getWasteGroup'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    formFocus () {
      this.$refs.WasterGroupName.focusCustomInput()
    },

    onSubmitForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async res => {
        if (!res) return

        if (this.isEdit) await this.UpdateWasteGroup()
        else await this.AddWasteGroup()
      })
    }, 'submitFormWasteGroup'),

    async AddWasteGroup () {
      const res = await this.createWasteGroup(this.form)
      if (res) {
        this.close()
        const message = this.$t('WasteGroup.add_success_message')
        this.notifyShow({ message })
        this.$emit('getList')
      }
    },

    async UpdateWasteGroup () {
      const res = await this.updateWasteGroup(this.form)
      if (res) {
        this.close()
        const message = this.$t('WasteGroup.edit_success_message')
        this.notifyShow({ message })
        this.$emit('getList')
      }
    }
  }
}
</script>
